@import 'react-toastify/dist/ReactToastify.css';
@import 'react-tabs/style/react-tabs.css';
@import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import 'react-datepicker/dist/react-datepicker.css';
@import 'react-tooltip/dist/react-tooltip.css';

@font-face {
  font-family: 'Fenwick';
  src: url('../public/fonts/Fenwick.ttf') format('truetype');
}

@font-face {
  font-family: 'Open24Display';
  src: url('../public/fonts/Open\ 24\ Display\ St.ttf') format('truetype');
}

@font-face {
  font-family: 'SegoeUI';
  src: url('../public/fonts/Segoe_UI.TTF') format('truetype');
}

@font-face {
  font-family: 'SanFrancisco';
  src: url('../public/fonts/SFUIDisplay.otf') format('opentype');
}

@font-face {
  font-family: 'OpenDyslexic';
  src: url('../public/fonts/OpenDyslexic.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto.ttf') format('truetype');
}

@font-face {
  font-family: 'Arial';
  src: url('../public/fonts/Arial.ttf') format('truetype');
}

@font-face {
  font-family: 'ComicSans';
  src: url('../public/fonts/Comic\ Sans.TTF') format('truetype');
}

@font-face {
  font-family: 'Verdana';
  src: url('../public/fonts/Verdana.ttf') format('truetype');
}

@font-face {
  font-family: 'Tahoma';
  src: url('../public/fonts/Tahoma.ttf') format('truetype');
}

@font-face {
  font-family: 'TrebuchetMS';
  src: url('../public/fonts/Trebuchet\ MS.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica';
  src: url('../public/fonts/Helvetica.ttf') format('truetype');
}

@font-face {
  font-family: 'IntelOneMono';
  src: url('../public/fonts/Intelone-mono.ttf') format('truetype');
}

@font-face {
  font-family: 'CenturyGothic';
  src: url('../public/fonts/Century\ Gothic.TTF') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins.otf') format('opentype');
}

@font-face {
  font-family: 'Digital';
  src: url('../public/fonts/digital.ttf') format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/*needed for rich text editor so that tailwind does not reset block types sizes*/
@layer base {
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    @apply text-4xl font-bold;
  }

  h2 {
    @apply text-3xl font-bold;
  }

  h3 {
    @apply text-2xl font-bold;
  }

  h4 {
    @apply text-xl font-semibold;
  }

  h5 {
    @apply text-lg font-semibold;
  }

  h6 {
    @apply text-sm font-semibold;
  }

  iframe#webpack-dev-server-client-overlay {
    display: none !important;
  }
}

@layer components {
  iframe {
    border-radius: 6px;
  }

  .create-pack-button {
    position: absolute !important;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .login-button {
    position: relative;
    z-index: 0;
    overflow: hidden;
  }

  .login-button::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: 0%;
    top: -250%;
    width: 100%;
    height: 600%;
    background-repeat: no-repeat;
    background-size: 50%, 50%;
    background-position:
      100% 0,
      0 100%;
    background-image: linear-gradient(#fcb500, #ffc533),
      linear-gradient(#f0c24d, #f6d98e);
    animation: rotate 6s linear infinite;
  }

  .login-button::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 5px;
    top: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: inherit;
    border-radius: 5px;
  }

  .login-button:hover::after,
  .login-button:hover::before {
    display: none;
  }

  .survey-stars span {
    line-height: clamp(2rem, 9vw, 6rem) !important;
    font-size: clamp(2.5rem, 10vw, 5rem) !important;
  }

  .data-table {
    @apply w-full lg:w-5/6 xl:w-3/4 h-fit table-fixed text-center border-separate;
  }

  .btn-primary {
    @apply w-fit h-fit px-2 sm:px-2.5 py-0.5 sm:py-1 text-xs sm:text-sm bg-gray-700 hover:bg-gray-800 text-white cursor-pointer rounded-md disabled:bg-gray-400 disabled:hover:bg-gray-400 disabled:cursor-default shadow flex items-center gap-1;
  }

  .input-text {
    @apply w-full px-2 py-1 outline-none rounded-md shadow bg-white focus:bg-gray-50 text-xs sm:text-sm lg:text-base;
  }

  .lessonCreateCompletionLoader {
    @apply w-12 aspect-square grid;
    -webkit-mask: conic-gradient(from 15deg, transparent, black);
    animation: rotate 1s infinite steps(12);

    background:
      radial-gradient(closest-side at 50% 12.5%, #e9902c 96%, transparent) 50% 0/20%
        80% repeat-y,
      radial-gradient(closest-side at 12.5% 50%, #e9902c 96%, transparent) 0 50%/80%
        20% repeat-x;
  }

  .lessonCreateCompletionLoader:before,
  .lessonCreateCompletionLoader:after {
    content: '';
    grid-area: 1 / 1;
    transform: rotate(30deg);
    background:
      radial-gradient(closest-side at 50% 12.5%, #e9902c 96%, transparent) 50% 0/20%
        80% repeat-y,
      radial-gradient(closest-side at 12.5% 50%, #e9902c 96%, transparent) 0 50%/80%
        20% repeat-x;
  }

  .lessonCreateCompletionLoader:after {
    transform: rotate(60deg);
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }

  @media screen and (max-width: 768px) {
    .create-pack-button {
      position: relative !important;
      margin-top: 15px;
    }

    table.data-table thead {
      display: none;
    }

    table.data-table {
      border-spacing: 8px;
    }

    table.data-table tr {
      line-height: 2.75em;
    }

    table.data-table td {
      display: block;
      text-align: right;
      padding-right: 1em;
    }

    table.data-table td::before {
      content: attr(data-label);
      float: left;
      font-weight: 500;
      padding-left: 1em;
    }
  }
}

@layer utilities {
  .triangle:after {
    content: '';
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    width: 35px;
    height: 35px;
    border-top: 4px solid white;
    border-right: 4px solid white;
    border-radius: 0.5rem;
    background-color: #fdbb74;
    transform: rotate(45deg);
    z-index: 40;
  }

  .vertical_text {
    writing-mode: vertical-lr;
    text-orientation: upright;
  }

  .aspect-ratio-2 {
    aspect-ratio: 2 / 1;
  }

  .circleDots {
    --shape: no-repeat radial-gradient(circle closest-side, #e9902c 90%, #0000);
    background:
      var(--shape) 0% 50%,
      var(--shape) 50% 50%,
      var(--shape) 100% 50%;
    background-size: calc(100% / 3) 50%;
  }
}
